import React from 'react';
import { Line } from 'react-chartjs-2'; // Importing Line chart from Chart.js
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { FaTruck, FaDollarSign, FaTag, FaWeightHanging } from 'react-icons/fa';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const Home = () => {
  // Chart Data
  const chartData = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul','Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    datasets: [
      {
        label: 'Total Tokens',
        data: [50, 100, 200, 150, 175, 220, 250,300,350,400,450,500],
        fill: false,
        borderColor: '#4bc0c0',
        tension: 0.1,
      },
    ],
  };

  // Chart Options
  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      tooltip: {
        mode: 'index',
        intersect: false,
      },
    },
  };

  return (
    <div
      style={{
        height: '100vh',
        margin: '0',
        padding: '0',
        overflowX: 'hidden',
        background: 'linear-gradient(rgb(0,0,0,0.2),rgb(0,0,0,0.1)), url("/b1.jpeg")',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
        backgroundSize: '100% 100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {/* Main Content */}
      <div style={{ padding: '20px', flex: 1 }}>
        {/* Statistics Boxes */}
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
            gap: '20px',
            justifyItems: 'center',
            marginBottom: '20px',
          }}
        >
          <div style={boxStyle}>
            <FaTag size={30} />
            <h3>Total Token</h3>
            <p>1250</p>
          </div>
          <div style={boxStyle}>
            <FaTag size={30} />
            <h3>Total Pending Token</h3>
            <p>350</p>
          </div>
          <div style={boxStyle}>
            <FaTruck size={30} />
            <h3>Last Vehicle No</h3>
            <p>KA-09-1234</p>
          </div>
          <div style={boxStyle}>
            <FaWeightHanging size={30} />
            <h3>Last Vehicle Weight</h3>
            <p>2000 Kg</p>
          </div>
          <div style={boxStyle}>
            <h1>₹</h1>
            <h3>Last Bill Amount</h3>
            <p>₹1500</p>
          </div>
        </div>

        {/* Chart Section */}
        <div style={{ marginTop: '30px' }}>
        
          <div style={{ height: '300px', width: '100%' }}>
            <Line data={chartData} options={chartOptions} />
          </div>
        </div>
      </div>

      {/* Footer */}
      <footer className="fixed-bottom">
        <marquee
          className="bg-dark text-light p-1"
          behavior="alternate"
          direction="left"
        >
          We offer customized software solutions that meet the requirements of your business. We have multiple software products with us with all required modules. (For more details, contact us at: +91-9205802778, Email: sujeetraj995@gmail.com)
        </marquee>
      </footer>
    </div>
  );
};

// Style for Information Boxes
const boxStyle = {
  backgroundColor: '#333',
  padding: '20px',
  borderRadius: '8px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
  textAlign: 'center',
  color: '#fff',
  fontSize: '1.2rem',
  transition: 'transform 0.3s ease',
};

export default Home;
