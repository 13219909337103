import React, { useEffect } from 'react'
import css from "./app.module.css"
import Navbar from './Component/Navbar'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import ItemUnit_View from './Component/ItemUnit_View'
import Home from './Component/Home'
import ItemGroupMaster from './Component/ItemGroupMaster'
import ItemMaster from './Component/ItemMaster'
import Logbook from './Component/Logbook'
import MessageBox from './Component/MessageBox'
import Settings from './Component/Settings'
import Company_Create from './Component/Company_Create'
import RecoveryyPassword from './Component/RecoveryyPassword'
import Passwordchange from './Component/Passwordchange'
import PrinterSelector from './Component/PrinterSelector'
import AccountMaster from './Component/AccountMaster'
import TokenView from './Component/TokenView'
import TokenNew from './Component/TokenNew'
const App = () => {

  return (
    <div id={css.app}>
          <Navbar/>
          <BrowserRouter>
            <Routes>
              <Route path='/' Component={Home}></Route>
              <Route path='/ItemUnit_View' Component={ItemUnit_View}></Route>
              <Route path='/ItemGroupMaster' Component={ItemGroupMaster}></Route>
              <Route path='/ItemMaster' Component={ItemMaster}/>
              <Route path='/Logbook' Component={Logbook}/>
              <Route path='/MessageBox' Component={MessageBox}/>
              <Route path='/Settings' element={<Settings/>}/>
              <Route path='/Company_Create' element={<Company_Create/>}/>
              <Route path='/AccountMaster' element={<AccountMaster/>}/>
              <Route path='/RecoveryyPassword' element={<RecoveryyPassword/>}/>
              <Route path='/Passwordchange' element={<Passwordchange/>}/>
              <Route path='/PrinterSelector' element={<PrinterSelector/>}/>
              <Route path='/TokenView' element={<TokenView/>}/>
              <Route path='/TokenNew' element={<TokenNew/>}/>
              
            </Routes>
          </BrowserRouter>     
    </div>
  )
}

export default App
